import React from "react";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-world-flags";
import { IoMdCloseCircle } from "react-icons/io";
// import "./mobileselect.css";
import i18n from "./i18n";

const options = [
  { value: "en", label: "English", code: "GB" },
  { value: "fr", label: "Français", code: "FR" },
  { value: "de", label: "Deutsch", code: "DE" },
  { value: "es", label: "Español", code: "ES" },
  { value: "zh", label: "中文", code: "CN" },
  { value: "ja", label: "日本語", code: "JP" },
  { value: "ar", label: "العربية", code: "SA" },
  { value: "pt", label: "Português", code: "PT" },
  { value: "it", label: "Italiano", code: "IT" },
];

const LanMobile = ({ closeModal }) => {
  const { i18n } = useTranslation();

  const handleChange = (value) => {
    // console.log("Changing language to:", value); //! Debug log
    i18n
      .changeLanguage(value)
      .then(() => {
        // console.log("Language changed successfully");
        closeModal(false); //! Close modal after selecting language
      })
      .catch((error) => {
        // console.error("Error changing language:", error);
      });
  };

  return (
    <div>
      <div className="modalBg">
        <div className="modalCont">
          <div onClick={() => closeModal(false)} className="closeModalButton">
            <IoMdCloseCircle />
          </div>
          <ul>
            {options.map((option) => (
              <li key={option.value} onClick={() => handleChange(option.value)}>
                <ReactCountryFlag
                  code={option.code}
                  svg
                  style={{ width: "20px", marginRight: "5px" }}
                />
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LanMobile;
