import React from "react";
import "./whatsapp.css";
import { IoLogoWhatsapp } from "react-icons/io";

const Whatsapp = () => {
  return (
    <a href="https://wa.me/441772821039" target="_blank" rel="noreferrer">
      <IoLogoWhatsapp className="whatsapp" />
      <div className="whatsapp-deskCont">
        WhatsApp <span>Click to chat</span>
      </div>
      <div className="whatsapp-deskContMobile">Chat Now!</div>
    </a>
  );
};

export default Whatsapp;
