import React, { useEffect } from "react";
import "./preston.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useTranslation } from "react-i18next";

const Preston = () => {
  const { t } = useTranslation();

  const aboutPrestonData = [
    {
      title: t("For_aboutpreston_shop_h1"),
      parah: t("For_aboutpreston_shop_p"),
      link: "https://www.visitpreston.com/shopping",
      imgLink:
        "https://images.pexels.com/photos/7987590/pexels-photo-7987590.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
    {
      title: t("For_aboutpreston_food_h1"),
      parah: t("For_aboutpreston_food_p"),
      link: "https://www.visitpreston.com/food-and-drink",
      imgLink:
        "https://images.pexels.com/photos/1267320/pexels-photo-1267320.jpeg?auto=compress&cs=tinysrgb&w=600",
    },

    {
      title: t("For_aboutpreston_thingstodo_h1"),
      parah: t("For_aboutpreston_thingstodo_p"),
      link: "https://www.visitpreston.com/things-to-see-and-do",
      imgLink:
        "https://images.pexels.com/photos/4997840/pexels-photo-4997840.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
    {
      title: t("For_aboutpreston_allabout_h1"),
      parah: t("For_aboutpreston_allabout_p"),
      link: "https://www.visitpreston.com/visit-blog",
      imgLink:
        "https://images.pexels.com/photos/16512862/pexels-photo-16512862/free-photo-of-close-up-of-individual-keyboard-keys-making-a-word-blog.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
  ];
  useEffect(() => {
    document.title = "About Preston - Preston Academy of English";
  }, []);
  return (
    <>
      <Header />
      <div className="aboutPreston-cont">
        <div className="preston-mainDetailCont">
          {aboutPrestonData.map((preston, index) => (
            <div className="preston-detailCont">
              <div className="pres-imgContDetail">
                <img src={preston.imgLink} alt="" />
              </div>
              <div className="pres-txtDetail">
                <div>
                  <h2>{preston.title}</h2>
                  <p>{preston.parah}</p>
                  <a href={preston.link} target="_blank" rel="noreferrer">
                    VisitPreston.com
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Preston;
