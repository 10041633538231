import React, { useEffect } from "react";
import "./studentAccom.css";
// //! Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";
//! Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
//! import required modules
// import { Pagination } from "swiper/modules";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// const accommmodation = [
//   {
//     title: "Friargate Court Student Accommodation",
//     slideTitle: "Friargate Court Accommodation",
//     link: "https://www.friargatepreston.com/",
//     paraRefund: "Friargate Court",
//     imgClassName: "friargate",
//     YTParah: "Friargate Court, Preston",
//     YoutubeLink: "https://www.youtube.com/embed/BHp-NpqrCj0",
//   },
// ];

const StudentAccom = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Student Accommodation - Preston Academy of English";
  }, []);

  // const [accom, setAccom] = useState(accommmodation[0]);

  // const handleSlideChange = (swiper) => {
  //   //! To get the current slide index
  //   const currentIndex = swiper.activeIndex;
  //   //! Update project state based on current slide index
  //   setAccom(accommmodation[currentIndex]);
  // };
  return (
    <>
      <Header />
      <div className="accom-cont">
        <div className="accom-swiper">
          <div className="trinity-img">
            <div className="friargate">
              <p>Friargate Court Accommodation</p>
            </div>
          </div>
        </div>
        <div className="accom-detailCont">
          <h1>Friargate Court Student Accommodation</h1>
          <span>{t("For_studaccom_span_head")}</span>
          <p>
            {t("For_studaccom_br1")} <br /> <br /> {t("For_studaccom_br2")}{" "}
            <br /> <br /> {t("For_studaccom_br3")} <br /> <br />
            <span>{t("For_studaccom_span_head2")}</span>
          </p>
          <ul>
            <li>{t("For_studaccom_li1")}</li>
            <li>{t("For_studaccom_li2")}</li>
            <li>WiFi</li>
            <li>{t("For_studaccom_li4")}</li>
            <li>{t("For_studaccom_li5")}</li>
            <li>{t("For_studaccom_li6")}</li>
            <li>{t("For_studaccom_li7")}</li>
          </ul>
          <div className="accom-smallDetailCont">
            <h4>{t("For_studaccom_span_head3")}</h4>
            <ul>
              <li>{t("For_studaccom_li8")}</li>
              <li>{t("For_studaccom_li9")}</li>
              <li>{t("For_studaccom_li10")}</li>
              <li style={{ color: "red" }}>{t("For_studaccom_li11")}</li>
            </ul>
            <h4>{t("For_studaccom_span_head4")}</h4>
          </div>
          <div className="accom-boldDetailCont">
            <a
              target="_blank"
              rel="noreferrer"
              className="accom-link"
              href="https://www.friargatepreston.com/"
            >
              {t("For_studaccom_P_red")}
            </a>
            <p className="accom-enquiry">
              {t("For_studaccom_P")}
              <NavLink to="/contact">{t("For_studaccom_P_red2")}</NavLink>
            </p>
          </div>
        </div>
        <div className="accom-YTCont">
          <iframe
            src="https://www.youtube.com/embed/BHp-NpqrCj0"
            title="Friargate Court, Preston"
          ></iframe>
          <p>Friargate Court, Preston</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default StudentAccom;

//
